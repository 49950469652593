import React from 'react';
import Navigation from "../components/navigation";

const About = () => {
    return (
        <body className="leading-normal tracking-normal text-white gradient">
            <Navigation />
            Treatments page!
        </body>
    )
}

export default About;
